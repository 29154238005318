<template>
  <div class="h-screen flex items-center justify-center overflow-hidden">
    <div class="relative flex w-full overflow-hidden" :style="`background-color: ${bg_color};`" style="max-width: 1000px; max-height: 1000px">
      <div id="canvas" class="items-middle">
        <!-- video -->
        <video v-if="is_video" :src="main_video_url" controls autoplay loop></video>
        <!-- base image -->
        <img v-if="!is_video" crossorigin="anonymous" @load="_main_loaded" :src="main_image" alt="" class="w-full object-contain" style="z-index: 0" />
        <!-- customs overlay -->
        <img crossorigin="anonymous" v-if="selected_custom && !is_video" @load="_customs_loaded" :src="customs_image" alt="" class="absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-contain" style="z-index: 1" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import versions from '@/assets/data/versions.json'
import collab_versions from '@/assets/data/collab_versions.json'
import background_reference from '@/assets/data/background_color.json'

import html2canvas from 'html2canvas'
import { saveAs } from 'file-saver'

export default {
  name: 'Download',
  components: {},
  data() {
    return {
      item_id: this.$route.params.id,
      type: this.$route.query.type,
      selected_version: this.$route.query.version,
      selected_custom: this.$route.query.custom,
      custom_variant: this.$route.query.variant,
      is_downloaded: false,
      // state flags
      main_loaded: false,
      customs_loaded: false,
      // adoptive background color
      metadata_url: `https://blazedcats.s3.us-east-2.amazonaws.com/metadata/current/${this.$route.params.id}`,
      item_metadata: null,
      bg_color: '#000000',
      text_color: '#ffffff',
      // customs
      customs_help_url: 'https://blazedcats.s3.us-east-2.amazonaws.com/custom_bot/help.txt',
      customs_unavailable: ['9991', '9992', '9993', '9994', '9995', '9996', '9997', '9998', '9999'],
      customs_blacklist: ['arms', 'bowling', 'check', 'fish', 'litterbox', 'spin'],
    }
  },
  computed: {
    is_video() {
      console.log("is_video", this.type == 'video')
      return this.type == 'video'
    },
    main_video_url() {
      return `https://blazedcats.s3.us-east-2.amazonaws.com/1000/${this.item_id}.mp4`
    },
    selected_customs() {
      return this.selected_customs_index || this.selected_customs_index == 0 ? this.overlay_items[this.selected_customs_index] : null
    },
    is_customs_available() {
      return !this.customs_unavailable.includes(this.item_id)
    },
    main_image() {
      return `https://blazedcats.s3.us-east-2.amazonaws.com/4000/${this.selected_version}/${this.item_id}.png`
    },
    customs_image() {
      return `https://blazedcats.s3.us-east-2.amazonaws.com/custom_bot/${this.selected_custom}/1000/${this.custom_variant}.png`
    },
    version_name() {
      if (!collab_versions.item_ids.includes(parseInt(this.item_id))) {
        // default version names
        let version_names = versions.flatMap((item) => {
          if (item.value == this.selected_version) {
            console.log(item.name.toLowerCase())
            return item.name.toLowerCase()
          } else {
            return []
          }
        })
        // console.log(version_names)
        return version_names[0]
      } else {
        // collabs
        let version_names = collab_versions.versions.flatMap((item) => {
          if (item[this.item_id].value == this.selected_version) {
            return item.name
          } else {
            return []
          }
        })
        console.log(version_names)
        return version_names[0]
      }
    }
  },
  async created() {
    await this.enable_adoptive_colors()
  },
  async mounted() {
  },
  methods: {
    _main_loaded() {
      this.main_loaded = true
      this._attempt_download()
    },
    _customs_loaded() {
      this.customs_loaded = true
      this._attempt_download()
    },
    _attempt_download() {
      if (this.is_downloaded || this.is_video) {
        // skip if already started downloading
        return
      }
      if (this.custom_variant && !this.customs_loaded) {
        // wait until customs image is loaded
        return
      }
      this.is_downloaded = true
      this._save_image()
    },
    async get_customs_data() {
      await axios
        .get(this.customs_help_url)
        .then((response) => {
          let help_txt = response.data
          this.overlay_items = help_txt.split('\r\n').filter((item) => {
            return item != ''
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    hexToR(h) {
      return parseInt(this.cutHex(h).substring(0, 2), 16)
    },
    hexToG(h) {
      return parseInt(this.cutHex(h).substring(2, 4), 16)
    },
    hexToB(h) {
      return parseInt(this.cutHex(h).substring(4, 6), 16)
    },
    cutHex(h) {
      return h.charAt(0) == '#' ? h.substring(1, 7) : h
    },
    async enable_adoptive_colors() {
      try {
        // get iframe metadata from aws
        await axios.get(this.metadata_url).then((response) => {
          const metadata = response.data
          const attributes = metadata.attributes
          // use reference to convert trait to color
          let background_color_trait = attributes.filter((attr) => attr.trait_type == 'background')[0].value
          let str_bg_color = background_color_trait.toLowerCase().replaceAll(' ', '_')
          let bg_color = background_reference[str_bg_color]
          this.bg_color = bg_color
          // store minimum metadata locally
          let fur_color_trait = attributes.filter((attr) => attr.trait_type == 'fur')[0].value
          this.item_metadata = {
            background: background_color_trait,
            fur: fur_color_trait
          }
          // adopt top left text color
          let h_red = Number(this.hexToR(bg_color))
          let h_green = Number(this.hexToG(bg_color))
          let h_blue = Number(this.hexToB(bg_color))
          this.text_color = h_red * 0.299 + h_green * 0.587 + h_blue * 0.114 > 186 ? '#000000' : '#ffffff'
        })
      } catch (error) {
        console.log(error)
      }
    },
    async _save_image() {
      console.log('saving image...')
      let image_name = `cat_${this.item_id}_${this.version_name}${this.selected_custom ? '_' + this.selected_custom : ''}.png`
      let imageContainer = document.getElementById('canvas')
      let appending_child = document.createElement('img');
      appending_child.style.position = 'absolute'
      appending_child.style.top = '50%'
      appending_child.style.left = '50%'
      appending_child.style.transform = 'translate(-50%, -50%)'
      appending_child.style.overflow = 'hidden'
      appending_child.style.objectFit = 'contain'
      appending_child.style.zIndex = 101
      imageContainer.style.width = '1000px'
      imageContainer.style.height = 'auto'
      html2canvas(imageContainer, { useCORS: true }).then(function (canvas) {
        appending_child.src = canvas.toDataURL()
        imageContainer.appendChild(appending_child)
        canvas.toBlob(function (blob) {
          console.log(blob)
          saveAs(blob, image_name)
        })
      })
    },
  },
}
</script>

<style scoped>
/* hide scrollbar but allow scrolling */
element {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

element::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
  background: transparent; /* make scrollbar transparent */
}
</style>
