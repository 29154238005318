<template>
  <div class="h-screen flex items-center justify-center overflow-hidden">
    <div class="relative flex w-full h-full overflow-hidden" :style="`background-color: ${bg_color};`" style="max-width: 500px; max-height: 500px">
      <div id="canvas" class="items-middle">
        <!-- base image -->
        <img v-if="!is_video" :src="main_image_url" alt="" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-contain no-user-action" style="z-index: 0" />
        <!-- customs overlay -->
        <img v-if="is_customs_available && selected_customs && !is_video" :src="customs_image" alt="" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-contain no-user-action" style="z-index: 1" />
        <!-- video item -->
        <video v-if="is_video" :src="main_video_url" controls autoplay loop class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-contain" style="z-index: 0"></video>
        <!-- <video v-if="is_video" preload="true" loop autoplay="autoplay" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-contain no-user-action" style="z-index: 0">
          <source :src="main_video_url" type="video/mp4">
        </video> -->
      </div>
      <!-- version toggole -->
      <div class="absolute top-2 right-2" style="z-index: 100">
        <div class="relative">
          <button @click="_toggleVersionDropDown" class="flex flex-col items-center rounded bg-gray-700 rounded" style="width: 4rem">
            <!-- <p class="font-number font-regular text-sm text-neutral-content">BCMD</p> -->
            <p class="font-number font-bold text-xs text-white">
              {{ selected_version }}
            </p>
          </button>
          <div v-if="versionDropDown" class="absolute left-0 right-0 mt-1 shadow-lg bg-gray-700 rounded z-20">
            <div class="flex flex-col items-center justify-center gap-y-1 py-2">
              <button @click="_updateVersionSelection(index)" v-for="(version, index) in versions" :key="index" class="relative w-full h-6 group">
                <div class="flex flex-row items-center justify-center whitespace-nowrap" :class="index == selected_version_index ? 'text-rug-green' : 'text-white'">
                  <div class="font-number font-bold text-xs text-center align-middle text-neutral-content">
                    {{ version.name }}
                  </div>
                </div>
                <!-- version description -->
                <div v-if="version.description" class="absolute top-0 bg-gray-700 mr-3 rounded p-2 mr-1 text-xs text-primary hidden group-hover:block" style="right: 100%">
                  {{ version.description }}
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- customs toggle -->
      <div v-if="is_customs_available && overlay_items && !is_video" class="absolute bottom-2 left-2" style="z-index: 100">
        <div class="relative flex items-end justify-start gap-x-1">
          <button @click="_toggleCustomsDropDown" class="flex flex-col items-center rounded px-2 bg-gray-700 rounded">
            <!-- <p class="font-number font-regular text-sm text-neutral-content">BCMD</p> -->
            <p class="font-number font-bold text-sm text-white capitalize">
              {{ selected_customs ? selected_customs : 'none' }}
            </p>
          </button>
        </div>
      </div>
      <div v-if="!is_video" class="absolute top-10 left-2 bottom-10 right-2 flex items-end justify-center overflow-visible">
        <div class="flex items-center justify-center">
          <div v-if="customsDropDown" class="rounded z-20">
            <div class="flex flex-row flex-wrap items-left justify-center gap-x-1 gap-y-1 py-2 px-1 overflow-y-scroll overflow-x-hidden bg-black bg-opacity-60">
              <button @click="_updateCustomsSelection(index)" v-for="(overlay, index) in overlay_items" :key="index" class="hover:bg-base-300">
                <button class="flex flex-col items-center justify-center shadow-lg bg-gray-700 rounded px-1" :class="index == selected_customs_index ? 'text-rug-green' : 'text-white'">
                  <div class="font-number font-bold text-sm text-center align-middle capitalize">
                    {{ overlay }}
                  </div>
                </button>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- 0xHeads stats -->
      <div class="absolute top-2 left-2" style="z-index: 100">
        <div class="flex flex-row items-center justify-start gap-x-2">
          <div class="rounded-full bg-accent" :class="is_head_claimed != null ? 'border-2 border-primary' : ''">
            <div v-if="is_head_claimed" @click="copy_0xheads_url" class="">
              <img crossorigin="anonymous" :src="`https://0xheads.s3.us-east-2.amazonaws.com/50/2.1.2/${item_id}.png`" alt="" class="rounded-full object-contain h-4" />
            </div>
            <div v-else-if="is_head_claimed != null" class="">
              <img crossorigin="anonymous" src="@/assets/icons/unknown-small-outline.svg" alt="" class="rounded-full object-contain h-4" />
            </div>
          </div>
          <!-- <div class="font-heading font-medium text-primary text-sm" style="text-shadow: 1px 2px 10px #111827;">{{ is_head_claimed ? '0xHEAD Claimed!' : '0xHEAD NOT CLAIMED!' }}</div> -->
          <div class="font-heading font-medium text-sm" :style="`color: ${text_color}`">{{ is_head_claimed == null ? '' : is_head_claimed ? '0xHEAD Claimed!' : '0xHEAD NOT CLAIMED!' }}</div>
        </div>
        <div class="absolute top-6 left-0 right-20 flex items-center justify-start">
          <div v-if="show_heads_message" class="font-body font-medium text-left text-xs bg-gray-700 text-primary rounded px-2 py-2 whitespace-nowrap">{{ heads_message }}</div>
        </div>
      </div>
      <div class="absolute top-12 left-2" style="z-index: 100"></div>
      <!-- save image -->
      <div class="absolute bottom-2 right-2" style="z-index: 100">
        <button @click="copy_image_download_url" class="bg-gray-700 rounded pb-1 px-1">
          <img src="@/assets/icons/download-icon.svg" alt="" class="object-contain h-6" />
        </button>
      </div>
      <div class="absolute bottom-2 right-12 left-12 flex items-end justify-end" style="z-index: 100">
        <div v-if="show_download_message" class="font-body font-medium text-right text-xs bg-gray-700 text-primary rounded px-3 py-2">{{ download_message }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import versions from '@/assets/data/versions.json'
import collab_versions from '@/assets/data/collab_versions.json'
import background_reference from '@/assets/data/background_color.json'

// import html2canvas from 'html2canvas'
// import { saveAs } from 'file-saver'

export default {
  name: 'Home',
  components: {},
  data() {
    return {
      item_id: this.$route.params.id,
      // adoptive background color
      metadata_url: `https://blazedcats.s3.us-east-2.amazonaws.com/metadata/current/${this.$route.params.id}`,
      item_metadata: null,
      bg_color: '#000000',
      text_color: '#ffffff',
      // versions
      versionDropDown: false,
      versions: null,
      selected_version_index: null,
      is_video: false,
      // customs
      customsDropDown: false,
      customs_help_url: 'https://blazedcats.s3.us-east-2.amazonaws.com/custom_bot/help.txt',
      overlay_items: null,
      selected_customs_index: null,
      customs_unavailable: ['9991', '9992', '9993', '9994', '9995', '9996', '9997', '9998', '9999'],
      customs_blacklist: ['arms', 'bowling', 'check', 'fish', 'litterbox', 'spin'],
      customs_background_specific: ['spin'],
      customs_fur_specific: ['arms', 'bowling', 'check'],
      video_token_ids: [9994],
      // 0xheads
      is_head_claimed: null,
      heads_message: null,
      show_heads_message: false,
      heads_check_url: `https://api.opensea.io/api/v1/asset/0xc6904fb685b4dfbdb98a5b70e40863cd9aef33dc/${this.$route.params.id}/`,
      heads_mint_url: 'https://0xheads.com/',
      heads_os_url: `https://opensea.io/assets/0xc6904fb685b4dfbdb98a5b70e40863cd9aef33dc/${this.$route.params.id}`,
      // download message
      download_message: null,
      show_download_message: false,
    }
  },
  computed: {
    main_image_url() {
      return `https://blazedcats.s3.us-east-2.amazonaws.com/1000/${this.selected_version_value}/${this.item_id}.png`
    },
    main_video_url() {
      return `https://blazedcats.s3.us-east-2.amazonaws.com/1000/${this.item_id}.mp4`
    },
    selected_version() {
      if (!this.selected_version_index) {
        this.selected_version_index = 0
      }
      return this.versions[this.selected_version_index].name
    },
    selected_version_value() {
      if (!this.selected_version_index) {
        this.selected_version_index = 0
      }
      return this.versions[this.selected_version_index].value
    },
    selected_customs() {
      return this.selected_customs_index || this.selected_customs_index == 0 ? this.overlay_items[this.selected_customs_index] : null
    },
    is_customs_available() {
      if (!this.customs_unavailable.includes(this.item_id)) {
        // default cats
        return true
      } else if (this.selected_version != 'Collab') {
        // collab cats not in collab state
        return true
      } else {
        // collab cats in collab state
        this.selected_customs_index = null
        return false
      }
    },
    customs_image() {
      if (this.customs_blacklist.includes(this.selected_customs)) {
        let fur_color = this.item_metadata.fur.toLowerCase()
        return `https://blazedcats.s3.us-east-2.amazonaws.com/custom_bot/${this.selected_customs}/1000/${fur_color}.png`
      } else {
        return `https://blazedcats.s3.us-east-2.amazonaws.com/custom_bot/${this.selected_customs}/1000/all.png`
      }
    },
    download_image_url() {
      if (this.is_video) {
        return window.location.origin + `/download/${this.item_id}?type=video`
      } else if (this.customs_blacklist.includes(this.selected_customs)) {
        let fur_color = this.item_metadata.fur.toLowerCase()
        return window.location.origin + `/download/${this.item_id}?version=${this.selected_version_value}${this.selected_customs ? `&custom=${this.selected_customs}&variant=${fur_color}` : ''}`
      } else {
        return window.location.origin + `/download/${this.item_id}?version=${this.selected_version_value}${this.selected_customs ? `&custom=${this.selected_customs}&variant=all` : ''}`
      }
    },
  },
  async created() {
    this.check_type(0)
    this.set_versions()
    await this.enable_adoptive_colors()
  },
  async mounted() {
    await this.get_customs_data()
    await this.get_heads_claim_status()
  },
  methods: {
    _toggleVersionDropDown() {
      this.customsDropDown = false
      this.versionDropDown = !this.versionDropDown
    },
    _toggleCustomsDropDown() {
      this.versionDropDown = false
      this.customsDropDown = !this.customsDropDown
    },
    _updateVersionSelection(index) {
      // toggle video mode
      this.check_type(index)
      // set index
      this.selected_version_index = index
      this._toggleVersionDropDown()
    },
    _updateCustomsSelection(index) {
      if (this.selected_customs_index == index) {
        // remove customs if same index is selected
        this.selected_customs_index = null
      } else {
        // update selection
        this.selected_customs_index = index
      }
      this._toggleCustomsDropDown()
    },
    check_type(index) {
      console.log(index)
      if (this.video_token_ids.includes(parseInt(this.item_id)) && index == 0) {
        this.is_video = true
      } else {
        this.is_video = false
      }
    },
    set_versions() {
      if (collab_versions.item_ids.includes(parseInt(this.item_id))) {
        // collab items version
        let versions = collab_versions.versions.map((item) => {
          let version = {
            name: item.name,
            value: item[String(this.item_id)].value,
            description: item.description,
          }
          return version
        })
        this.versions = versions
      } else {
        // normal items version
        this.versions = versions
      }
    },
    async get_customs_data() {
      await axios
        .get(this.customs_help_url)
        .then((response) => {
          let help_txt = response.data
          this.overlay_items = help_txt.split('\r\n').filter((item) => {
            return item != ''
          })
        })
        .catch((error) => {
          console.log(error)
        })
    },
    async get_heads_claim_status() {
      try {
        await axios.get(this.heads_check_url, { validateStatus: false }).then((response) => {
          let result = response.data
          if (!result.id) {
            this.is_head_claimed = falsec
          } else {
            this.is_head_claimed = true
          }
        })
      } catch (error) {
        // 404 when not minted
        this.is_head_claimed = false
      }
    },
    hexToR(h) {
      return parseInt(this.cutHex(h).substring(0, 2), 16)
    },
    hexToG(h) {
      return parseInt(this.cutHex(h).substring(2, 4), 16)
    },
    hexToB(h) {
      return parseInt(this.cutHex(h).substring(4, 6), 16)
    },
    cutHex(h) {
      return h.charAt(0) == '#' ? h.substring(1, 7) : h
    },
    async enable_adoptive_colors() {
      try {
        // get iframe metadata from aws
        await axios.get(this.metadata_url).then((response) => {
          const metadata = response.data
          const attributes = metadata.attributes
          // use reference to convert trait to color
          let background_color_trait = attributes.filter((attr) => attr.trait_type == 'background')[0].value
          let str_bg_color = background_color_trait.toLowerCase().replaceAll(' ', '_')
          let bg_color = background_reference[str_bg_color]
          this.bg_color = bg_color
          // store minimum metadata locally
          let fur_color_trait = attributes.filter((attr) => attr.trait_type == 'fur')[0].value
          this.item_metadata = {
            background: background_color_trait,
            fur: fur_color_trait
          }
          // adopt top left text color
          let h_red = Number(this.hexToR(bg_color))
          let h_green = Number(this.hexToG(bg_color))
          let h_blue = Number(this.hexToB(bg_color))
          this.text_color = h_red * 0.299 + h_green * 0.587 + h_blue * 0.114 > 186 ? '#000000' : '#ffffff'
        })
      } catch (error) {
        console.log(error)
      }
    },
    _toggle_show_heads_url_copied_message(bool, message) {
      this.heads_message = message
      this.show_heads_message = bool
    },
    _toggle_show_download_message(bool, message) {
      this.download_message = message
      this.show_download_message = bool
    },
    copy_0xheads_url() {
      var image_download_el = document.createElement('textarea')
      image_download_el.value = this.heads_os_url
      image_download_el.style.top = '0'
      image_download_el.style.left = '0'
      image_download_el.style.position = 'fixed'
      image_download_el.style.opacity = 0
      document.body.appendChild(image_download_el)
      image_download_el.focus()
      image_download_el.select()
      try {
        let success = document.execCommand('copy')
        this._toggle_show_heads_url_copied_message(true, '0xheads link copied!')
        setTimeout(() => {
          this._toggle_show_heads_url_copied_message(false, null)
        }, 1500)
      } catch (error) {
        this._toggle_show_heads_url_copied_message(true, 'Could not copy 0xheads image url')
        setTimeout(() => {
          this._toggle_show_heads_url_copied_message(false, null)
        }, 1500)
        console.log(error)
      }
      document.body.removeChild(image_download_el)
    },
    _toggle_show_download_message(bool, message) {
      this.download_message = message
      this.show_download_message = bool
    },
    copy_image_download_url() {
      var image_download_el = document.createElement('textarea')
      image_download_el.value = this.download_image_url
      image_download_el.style.top = '0'
      image_download_el.style.left = '0'
      image_download_el.style.position = 'fixed'
      document.body.appendChild(image_download_el)
      image_download_el.focus()
      image_download_el.select()
      try {
        let success = document.execCommand('copy')
        this._toggle_show_download_message(true, 'Image link copied!')
        setTimeout(() => {
          this._toggle_show_download_message(false, null)
        }, 1500)
      } catch (error) {
        this._toggle_show_download_message(true, 'Could not copy image url')
        setTimeout(() => {
          this._toggle_show_download_message(false, null)
        }, 1500)
        console.log(error)
      }
      document.body.removeChild(image_download_el)
    },
    // async _save_image() {
    //   console.log('saving image...')
    //   let imageContainer = document.getElementById('canvas')
    //   imageContainer.style.height = '500px'
    //   imageContainer.style.width = '500px'
    //   html2canvas(imageContainer, { useCORS: true }).then(function (canvas) {
    //     canvas.toBlob(function (blob) {
    //       console.log(blob)
    //       saveAs(blob, 'image.png')
    //     })
    //   })
    // },
  },
}
</script>

<style scoped>
.no-user-action {
  user-select: none;
  pointer-events: none;
}
/* hide scrollbar but allow scrolling */
element {
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  scrollbar-width: none; /* for Firefox */
  overflow-y: scroll;
}

element::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
  background: transparent; /* make scrollbar transparent */
}
</style>
